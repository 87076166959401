import { Add, Check, UnfoldMore } from '@mui/icons-material';
import { Avatar, FormControl, List, ListItemButton, ListItemText, Menu, MenuItem } from '@mui/material';
import cx from 'classnames';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'wouter';
import * as DriveAPI from '~/utils/DriveAPI';
import { type DriveFile } from '~/utils/DriveAPI';
import { DEV, isBun, LEGACY, publicDocsId } from '~/utils/constants';
import gup from '~/utils/gup';
import { useKeyPress } from '~/utils/hooks';
import { type Profile } from '~/utils/types';
import { colorIsDark, isAShortcut, isInputEvent, isModalEvent } from '~/utils/utils';
import * as styles from './WikiSelector.css';
import { menuItemActive } from './WikiSelector.css';

const { size } = styles;

const Logo = ({
  alt,
  className,
  src,
  style,
  wiki,
}: { alt: string; className?: string; src: string; style?: React.CSSProperties; wiki: DriveFile }) => {
  const [thumbnailURL, setThumbnailURL] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => setThumbnailURL(null), [src]);
  return (
    <Avatar
      {...(DEV && {
        imgProps: {
          referrerPolicy: 'no-referrer',
        },
      })}
      {...(className && { className })}
      {...(style && { style })}
      {...(!loaded && {
        style: {
          ...style,
          background: 'rgba(0, 0, 0, 0.08)',
        },
      })}
      slotProps={{
        img: {
          style: {
            // Allows for wide images
            objectFit: 'contain',
          },
        },
      }}
      alt={alt}
      src={thumbnailURL ?? src}
      // Test error handling
      // src=""
      // src="https://example.com/404"
      onLoad={() => setLoaded(true)}
      onError={async () => {
        if (thumbnailURL) return;
        const { logoId } = wiki.properties ?? {};
        if (!logoId) throw new Error('No logoId found');
        setLoading(true);
        const logo = await DriveAPI.get({ id: logoId, fields: 'thumbnailLink' });
        if (!logo?.thumbnailLink) {
          setLoading(false);
          throw new Error('No logo found');
        }
        const url = logo.thumbnailLink.replace('s220', `s${size * window.devicePixelRatio}`);
        setThumbnailURL(url);
        setTimeout(() => setLoading(() => false), 50);
        setTimeout(() => setLoaded(() => true), 200);
      }}
    >
      {loading === true ? (
        <></>
      ) : (
        <Avatar className={styles.fallbackLogo}>
          <span className={styles.letterFallback}>{wiki.name.charAt(0)}</span>
        </Avatar>
      )}
    </Avatar>
  );
};

const Button = ({
  currentWiki,
  logoId,
  logoURL,
  unicode,
  fontColor,
  isPublic,
  bgColorIsLight,
  isLink,
}: {
  currentWiki: DriveFile;
  logoId: string | undefined;
  logoURL: string | undefined;
  unicode: string | undefined;
  fontColor: string | undefined;
  isPublic: boolean | undefined;
  bgColorIsLight: boolean | string | undefined;
  isLink?: boolean;
}) => {
  return (
    <ListItemButton className={styles.button} title="Select a wiki (w)">
      {/* Logo */}
      {logoId && !unicode && (
        <Logo
          alt="Wiki logo"
          className={styles.buttonLogo}
          src={
            // If it's public we can save an additional request
            isPublic
              ? `https://lh3.googleusercontent.com/d/${logoId}=w${size * window.devicePixelRatio}`
              : `https://drive.google.com/thumbnail?sz=w${size * window.devicePixelRatio}&id=${logoId}`
          }
          wiki={currentWiki}
          style={{
            // These are styles for the fallback letter
            ...(bgColorIsLight && {
              background: 'rgba(0, 0, 0, 0.08)',
              color: fontColor,
            }),
          }}
        />
      )}
      {/* Custom Logo URL */}
      {!logoId && !unicode && logoURL && (
        <Logo
          alt="Wiki logo"
          className={styles.buttonLogo}
          src={logoURL}
          wiki={currentWiki}
          style={{
            // These are styles for the fallback letter
            ...(bgColorIsLight && {
              background: 'rgba(0, 0, 0, 0.08)',
              color: fontColor,
            }),
          }}
        />
      )}

      {/* Letter */}
      {!logoId && !unicode && !logoURL && (
        <Avatar
          className={styles.letter}
          style={{
            ...(bgColorIsLight && { background: 'rgba(0, 0, 0, 0.08)' }),
            color: fontColor,
          }}
        >
          {currentWiki.name.charAt(0)}
        </Avatar>
      )}
      {/* Emoji */}
      {unicode && (
        <div
          className={styles.unicode}
          style={{ fontSize: 34, lineHeight: 0, width: 34, height: 34, display: 'flex', alignItems: 'center' }}
        >
          {unicode}
        </div>
      )}

      <ListItemText
        primaryTypographyProps={{
          className: styles.buttonText,
          style: {
            color: fontColor,
          },
        }}
        primary={currentWiki.name}
      />
      {!isLink && (
        <UnfoldMore
          style={{
            color: fontColor ?? '#fff',
          }}
        />
      )}
    </ListItemButton>
  );
};

const WikiSelector = ({ wiki: currentWiki, wikis }: { wiki: DriveFile; wikis: DriveFile[]; user: Profile }) => {
  const { logoId, logoURL, unicode, color, fontColor } = currentWiki.properties ?? {};
  const menuState = usePopupState({ variant: 'popover', popupId: 'WikiSelector' });
  const anchorRef = useRef<HTMLButtonElement>(null);
  const bgColorIsLight = color && !colorIsDark(color);
  const isPublic = currentWiki.permissions?.some((permission) => permission.type === 'anyone');
  const isReader = currentWiki.capabilities?.canEdit === false;
  const hideCreateWikiButton =
    currentWiki.properties?.showCreateWikiButton === 'false' && currentWiki.capabilities?.canEdit === false;

  useKeyPress('w', (e) => {
    if (isInputEvent(e)) return;
    if (isModalEvent(e)) return;
    menuState.setAnchorEl(anchorRef.current);
    menuState.open();
  });

  if (isReader && wikis.length === 0) {
    return (
      <FormControl className={styles.buttonContainer}>
        <List
          className={styles.buttonLink}
          component={Link}
          aria-label="Wiki selector"
          to={`/app/page/${currentWiki.id}/${currentWiki.id}`}
        >
          <Button
            currentWiki={currentWiki}
            logoId={logoId}
            logoURL={logoURL}
            unicode={unicode}
            fontColor={fontColor}
            isPublic={isPublic}
            bgColorIsLight={bgColorIsLight}
            isLink
          />
        </List>
      </FormControl>
    );
  }

  return (
    <>
      {/* Button */}
      <FormControl className={styles.buttonContainer}>
        <List component="nav" aria-label="Wiki selector" ref={anchorRef} {...bindTrigger(menuState)}>
          <Button
            currentWiki={currentWiki}
            logoId={logoId}
            logoURL={logoURL}
            unicode={unicode}
            fontColor={fontColor}
            isPublic={isPublic}
            bgColorIsLight={bgColorIsLight}
          />
        </List>
      </FormControl>

      {/* Menu */}
      <Menu
        {...bindMenu(menuState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transitionDuration={{
          enter: isBun ? 0 : 250,
          exit: 0,
        }}
        className={styles.menu}
      >
        {wikis.map((wiki) => {
          const id = isAShortcut(wiki) ? wiki.shortcutDetails.targetId : wiki.id;
          const { logoId, unicode, logoURL } = wiki.properties ?? {};
          const isSelected = currentWiki.id === id;
          const isPublic = currentWiki.permissions?.some((permission) => permission.type === 'anyone');
          const { color, fontColor } = wiki.properties ?? {};
          const bgColorIsDark = color ? colorIsDark(color) : false;

          // if (wiki.name.startsWith('Empty')) {
          //   console.log('>', color, fontColor, bgColorIsDark);
          // }

          // Temp fix until we disable use of iframes
          // Don't show docs.youneedawiki.com in the wiki selector for docs.youneedawiki.com
          if (LEGACY && wiki.id === publicDocsId && gup('parentURL') === 'https://docs.youneedawiki.com') return null;

          return (
            <MenuItem
              key={wiki.id}
              onClick={() => {
                menuState.close();
                if (LEGACY) {
                  window.legacyHistory?.push(`/app/page/${id}`);
                  window.onWikiChange?.(id);
                }
              }}
              component={Link}
              to={LEGACY ? `/app/page/${id}` : `/app/page/${wiki.id}/${wiki.id}`}
              autoFocus={isSelected}
              className={cx(styles.menuItem, {
                [menuItemActive]: isSelected,
              })}
              focusVisibleClassName={styles.menuItemFocusVisible}
            >
              <div className={styles.menuItemText}>
                {wiki.name}
                {isSelected && <Check className={styles.check} />}
              </div>
              {unicode && <Avatar className={styles.unicode}>{unicode}</Avatar>}
              {/* Logo */}
              {logoId && !unicode && (
                <Logo
                  className={styles.logo}
                  src={
                    isPublic
                      ? `https://lh3.googleusercontent.com/d/${logoId}=w${size * window.devicePixelRatio}`
                      : `https://drive.google.com/thumbnail?sz=w${size * window.devicePixelRatio}&id=${logoId}`
                  }
                  alt="Wiki logo"
                  wiki={wiki}
                />
              )}
              {/* Legacy: fallback for logoURL */}
              {logoURL && !logoId && !unicode && (
                <Logo className={styles.logo} src={logoURL} alt="Wiki logo" wiki={wiki} />
              )}
              {/* Letter */}
              {!logoId && !unicode && !logoURL && (
                <Avatar
                  className={styles.menuLetter}
                  style={{
                    backgroundColor: color,
                    color: bgColorIsDark ? '#fff' : fontColor === '#FFFFFF' ? undefined : fontColor,
                  }}
                  role="presentation"
                >
                  {wiki.name.charAt(0)}
                </Avatar>
              )}
            </MenuItem>
          );
        })}

        {/* New wiki */}
        {!hideCreateWikiButton && (
          <MenuItem
            onClick={() => {
              menuState.close();
              if (LEGACY) {
                window.legacyHistory?.push('/app/create');
              }
            }}
            component={Link}
            to="/app/create"
            className={styles.menuItem}
            focusVisibleClassName={styles.menuItemFocusVisible}
            style={{
              flexDirection: 'row',
            }}
          >
            <Avatar className={styles.addIcon}>
              <Add style={{ color: 'currentcolor' }} />
            </Avatar>
            {/* Allows searching for "+" */}
            <span
              style={{
                opacity: 0,
                width: 0,
                height: 0,
                position: 'absolute',
                overflow: 'hidden',
              }}
            >
              +
            </span>
            <div className={styles.menuItemText}>Create a new wiki</div>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default WikiSelector;
